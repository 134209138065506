import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';

import { ONA_ACTIONS, ONA_ACTION_SUBTYPES } from '../ONA/actions';

const { REACT_APP_API_URL } = process.env;

const ENDPOINTS = {
  [ONA_ACTION_SUBTYPES.EMAIL_COLLABORATION]: 'analysis/emails_collaboration',
  [ONA_ACTION_SUBTYPES.MEETING_COLLABORATION]: 'analysis/meetings_collaboration',
  [ONA_ACTION_SUBTYPES.DEPARTMENT_COLLABORATION]: 'analysis/departments_collaboration',
  [ONA_ACTION_SUBTYPES.TYPE_BREAKDOWN]: 'analysis/type_breakdown',
  [ONA_ACTION_SUBTYPES.GRAPH]: 'analysis/graph',
  [ONA_ACTION_SUBTYPES.ENGAGEMENT]: 'analysis/engagement',
  [ONA_ACTION_SUBTYPES.FOCUS]: 'analysis/focus',
  [ONA_ACTION_SUBTYPES.BALANCE]: 'analysis/balance',
  [ONA_ACTION_SUBTYPES.WELLBEING_METRICS]: 'analysis/metrics',
  [ONA_ACTION_SUBTYPES.WELLBEING_AVERAGES]: 'analysis/averages',
  [ONA_ACTION_SUBTYPES.DEPARTMENTS]: 'general/departments',
  [ONA_ACTION_SUBTYPES.LOCATIONS]: 'general/locations',
  [ONA_ACTION_SUBTYPES.PRODUCTIVITY_RANKING]: 'analysis/productivity_ranking',
  [ONA_ACTION_SUBTYPES.SEARCH_EMPLOYEES]: 'general/employee_search',
};

function* getAPIData({ subtype, data }) {
  try {
    const filteredData = { ...data };
    Object.keys(filteredData).forEach(
      (key) => (filteredData[key] == null) && delete filteredData[key],
    );
    const { data: response } = yield call(axios.post, `${REACT_APP_API_URL}${ENDPOINTS[subtype]}`, filteredData);

    if (response.status === 1) {
      yield put({
        type: ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING,
        subtype,
      });
    } else {
      yield put({
        type: ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS,
        data: response.data,
        subtype,
      });
    }
  } catch (e) {
    console.error(e);
    yield put({
      type: ONA_ACTIONS.LOAD_ONA_DATA_ERROR,
      message: e.message,
      subtype,
    });
  }
}

function* onaSaga() {
  yield takeEvery(ONA_ACTIONS.LOAD_ONA_DATA, getAPIData);
}

export default onaSaga;
