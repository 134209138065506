import { ONA_ACTIONS, ONA_ACTION_SUBTYPES } from './actions';

const COLORS = [
  '#27A4A1', '#FFCA28', '#9479F2', '#81F279', '#F279A5', '#79C8F2', '#EBF279',
  '#D679F2', '#7986F2', '#AAF279', '#F279CD', '#79F0F2',
  '#F2D179', '#AD79F2', '#79F28A', '#F2798C', '#79AFF2', '#D2F279', '#EF79F2',
];
const MIN_NODE_SIZE = 80;
const MAX_NODE_SIZE = 800;

const processGraphData = (data) => {
  const departments = [...new Set(data.map((e) => e.employee.department))]
    .map((department, index) => ({
      name: department,
      color: COLORS[index],
    }));
  const ranks = [...new Set(data.map(({ rank }) => rank))];
  const maxRank = ranks.reduce((a, b) => Math.max(a, b), 0);
  const minRank = ranks.filter((r) => r > 0).reduce((a, b) => Math.min(a, b), ranks.length);
  const nodes = [];
  let links = [];

  data
    // .slice(0, 5)
    .sort((a, b) => a.rank - b.rank)
    .filter(({ employee, connections }) => connections.length && employee.email)
    .forEach(({ employee, connections }, index, array) => {
      // const spread = Math.ceil(array.length / 20);

      nodes.push({
        ...employee,
        id: employee.email,
        color: departments.find((d) => d.name === employee.department).color,
        size: (((connections.length - minRank) * (MAX_NODE_SIZE - MIN_NODE_SIZE))
          / (maxRank - minRank)) + MIN_NODE_SIZE,
        // x: 100 + 80 * (index % 10),
        // y: 50 + 80 * Math.floor(index / 10),
        x: 500 + 6 * (index + 22) * Math.cos((index + 22) / 4),
        y: 200 + 4 * (index + 22) * Math.sin((index + 22) / 4),
      });
      links = links.concat(
        connections
          .filter((c) => array.find((d) => d.employee.email === c))
          .map((connection) => ({
            source: employee.email,
            target: connection,
          })),
      );
    });

  return { nodes, links, departments };
};

const initialState = Object.values(ONA_ACTION_SUBTYPES)
  .reduce((a, subtype) => ({
    ...a,
    [subtype]: {
      loading: false,
      loaded: false,
      isPending: false,
      error: null,
      data: null,
    },
  }), {
    currentFilters: {
      DEPARTMENT: null,
      LOCATION: null,
      USER: null,
      START_DATE: null,
      END_DATE: null,
      INTERVAL: null,
    },
  });

export default (state = initialState, action) => {
  let processedData = null;

  switch (action.type) {
    case ONA_ACTIONS.SET_FILTERS:
      switch (action.subtype) {
        case ONA_ACTION_SUBTYPES.DATE:
          return {
            ...initialState,
            [ONA_ACTION_SUBTYPES.DEPARTMENTS]: state[ONA_ACTION_SUBTYPES.DEPARTMENTS],
            [ONA_ACTION_SUBTYPES.LOCATIONS]: state[ONA_ACTION_SUBTYPES.LOCATIONS],
            currentFilters: {
              ...state.currentFilters,
              START_DATE: action.data.startDate,
              END_DATE: action.data.endDate,
              INTERVAL: action.data.interval,
            },
          };
        default:
          return {
            ...initialState,
            [ONA_ACTION_SUBTYPES.DEPARTMENTS]: state[ONA_ACTION_SUBTYPES.DEPARTMENTS],
            [ONA_ACTION_SUBTYPES.LOCATIONS]: state[ONA_ACTION_SUBTYPES.LOCATIONS],
            currentFilters: {
              ...state.currentFilters,
              [action.subtype]: action.data.option,
            },
          };
      }
    case ONA_ACTIONS.LOAD_ONA_DATA:
      return {
        ...state,
        [action.subtype]: {
          ...state[action.subtype],
          loading: true,
          isPending: false,
          error: null,
        },
      };

    case ONA_ACTIONS.LOAD_ONA_DATA_SUCCESS:
      switch (action.subtype) {
        case ONA_ACTION_SUBTYPES.GRAPH:
          processedData = processGraphData(action.data.employees_data);
          break;

        default:
          processedData = action.data;
          break;
      }

      return {
        ...state,
        [action.subtype]: {
          ...state[action.subtype],
          loading: false,
          loaded: true,
          data: processedData,
          error: null,
        },
      };

    case ONA_ACTIONS.LOAD_ONA_DATA_IS_PENDING:
      return {
        ...state,
        [action.subtype]: {
          ...state[action.subtype],
          loading: false,
          loaded: true,
          data: null,
          error: null,
          isPending: true,
        },
      };

    case ONA_ACTIONS.LOAD_ONA_DATA_ERROR:
      return {
        ...state,
        [action.subtype]: {
          ...state[action.subtype],
          loading: false,
          loaded: true,
          error: action.message,
        },
      };

    default:
      return state;
  }
};
