import styled from "styled-components";

// THEME
import { Layout } from 'antd';
import { Menu } from 'antd';

// CONST
import { DIMENTIONS, responsive } from "Styles/Constants";
const { Sider } = Layout;

export const SidebarWrapper = styled(Sider)`

    margin  : 64px 0 0;
    background : #fff;
    overflow : hidden;
    position : relative;
    z-index:2;
    left : 0;
    display: none;
    
    .icon-image {
        align-items       : center;
        display           : flex;
    }
    .ant-layout {
        background: #EAEAEA;
    }
    .ant-layout-sider-children {
        border-right: 2px solid #EAEAEA;
    }

    .ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after {
        right: auto;
        left: 0;
        border-width: 5px;
    }

    ${responsive.PHABLET`
        width : ${props => props.collapsed && "0 !important"};
        min-width : ${props => props.collapsed && "0 !important"};
        flex : ${props => props.collapsed && "none !important"};
    `}

    ${props => props.collapsed ?   
        `.icon-image > span {
            display : none;
        }
        .icon-image > .sidebar-icon {
            margin : 0;
        }
        ` : ''}
`

export const LogoWrapper = styled.div`
height : ${DIMENTIONS.HEADER}px;

.img-logo {
width : 180px;
}
`

export const MenuItem = styled(Menu.Item)`
font-size : 16px;
transition : .3s;

.anticon {
font-size: 16px;
}
`