import React from 'react';
import { CommonPagesHeaderWrapper } from "./Header.style";
import LogoImage from "Assets/Images/logo.png";

function CommonPagesHeader(props) {

  return (
    <div className="header">
        <CommonPagesHeaderWrapper>
            <img className="img-logo" src={LogoImage} alt="Logo" />    
        </CommonPagesHeaderWrapper>
    </div>
  );
}

export default CommonPagesHeader;