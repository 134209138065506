export const ONA_ACTIONS = {
  LOAD_ONA_DATA: 'LOAD_ONA_DATA',
  LOAD_ONA_DATA_SUCCESS: 'LOAD_ONA_DATA_SUCCESS',
  LOAD_ONA_DATA_ERROR: 'LOAD_ONA_DATA_ERROR',
  LOAD_ONA_DATA_IS_PENDING: 'LOAD_ONA_DATA_IS_PENDING',
  SET_FILTERS: 'SET_FILTERS',
};

export const ONA_ACTION_SUBTYPES = {
  // LOAD DATA SUBTYPES
  EMAIL_COLLABORATION: 'EMAIL_COLLABORATION',
  MEETING_COLLABORATION: 'MEETING_COLLABORATION',
  DEPARTMENT_COLLABORATION: 'DEPARTMENT_COLLABORATION',
  TYPE_BREAKDOWN: 'TYPE_BREAKDOWN',
  GRAPH: 'GRAPH',
  ENGAGEMENT: 'ENGAGEMENT',
  FOCUS: 'FOCUS',
  BALANCE: 'BALANCE',
  WELLBEING_METRICS: 'WELLBEING_METRICS',
  WELLBEING_AVERAGES: 'WELLBEING_AVERAGES',
  DEPARTMENTS: 'DEPARTMENTS',
  LOCATIONS: 'LOCATIONS',
  PRODUCTIVITY_RANKING: 'PRODUCTIVITY_RANKING',
  // FILTERS SUBTYPES
  DEPARTMENT: 'DEPARTMENT',
  LOCATION: 'LOCATION',
  USER: 'USER',
  DATE: 'DATE',
  // SEARCH EMPLOYEES
  SEARCH_EMPLOYEES: 'SEARCH_EMPLOYEES',
};

export const setDateFilter = (data) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.DATE,
  data,
});

export const setUserFilter = (data) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.USER,
  data,
});

export const setLocationFilter = (data) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.LOCATION,
  data,
});

export const setDepartmentFilter = (data) => ({
  type: ONA_ACTIONS.SET_FILTERS,
  subtype: ONA_ACTION_SUBTYPES.DEPARTMENT,
  data,
});

export const getLocations = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.LOCATIONS,
  data,
});

export const getDepartments = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.DEPARTMENTS,
  data,
});

export const getEmailCollaborationData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.EMAIL_COLLABORATION,
  data,
});

export const getMeetingCollaborationData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.MEETING_COLLABORATION,
  data,
});

export const getDepartmentsCollaborationData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.DEPARTMENT_COLLABORATION,
  data,
});

export const getTypeBreakdownData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.TYPE_BREAKDOWN,
  data,
});

export const getGraphData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.GRAPH,
  data,
});

export const getEngagementData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.ENGAGEMENT,
  data,
});

export const getFocusData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.FOCUS,
  data,
});

export const getBalanceData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.BALANCE,
  data,
});

export const getWellbeingMetricsData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.WELLBEING_METRICS,
  data,
});

export const getWellbeingAveragesData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.WELLBEING_AVERAGES,
  data,
});

export const getProductivityRankingData = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.PRODUCTIVITY_RANKING,
  data,
});

export const searchEmployees = (data) => ({
  type: ONA_ACTIONS.LOAD_ONA_DATA,
  subtype: ONA_ACTION_SUBTYPES.SEARCH_EMPLOYEES,
  data,
});
