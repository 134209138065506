import { ACTION_TYPES } from './Actions';

const initState = {
    isLoggedIn: localStorage.getItem('isLoggedIn') ? (localStorage.getItem('isLoggedIn') === "true") : false,
    collapsed: window.innerWidth > 1220 ? false : true,
    width: window.innerWidth
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case ACTION_TYPES.LOGIN_USER:
            return {
                ...state,
                isLoggedIn: true
            };

        case ACTION_TYPES.LOGOUT_USER:
            return {
                ...state,
                isLoggedIn: false
            };
        case ACTION_TYPES.COLLPSE_CHANGE:
            console.log("this");

            return { ...state, collapsed: !state.collapsed };
        default:
            return state;
    }

}

export default Reducer;