import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import { Button, Input, Form, message, Select } from 'antd';
import { withRouter } from "react-router-dom";
import queryString from 'query-string';
// STYLES
import { LoginWrapper, LoginBox, LogoWrapper } from "./Login.style";

// CONST
import { URL_ONA } from "Helpers/Paths";
import { loginUser } from "Redux/Auth/Actions";

// IMAGES
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import LogoImage from "Assets/Images/logo.png";

import { API_PATH } from "../../../Helpers/Paths";
import Api from '../../../Helpers/ApiHandler';
const API = new Api();
const { Option } = Select;
function Login(props) {
    const [companyData, setCompanyData] = useState(null);
    useEffect(()=>{
        let params = queryString.parse(props.location.search)
        
        checkIfAlreadyLoggedIn();
        if(params.ref_id){
            companyDetail(params.ref_id);
        }
    // eslint-disable-next-line
    }, [])

    function checkIfAlreadyLoggedIn() {
        if(props.isLoggedIn) 
            props.history.push(URL_ONA)
    }

    const companyDetail = (ref) => {
        let companyUrl = API_PATH.COMPANY_DETAIL+ref+'/';
        let data = {
            skipAuth : true
        }
        API.get(companyUrl, data).then((res) => {
        if (res.status === 200) {
            setCompanyData(res.data);
        }
        });
    }

    function loginUser(values) {
        // TODO: remove the following code block
        // This is setup to allow login for ONA dev
        setImmediate(() => {
            localStorage.setItem('accessToken', '');
            props.loginUser({
                data: { auth_token: '' },
            });
            props.history.push(URL_ONA);
        });
        return;
        // SET YOUR LOGIN INFO HERE
        let payload = {
            data:  {
                company_id: companyData ? companyData.id : 0,
                email: values.username,
                password: values.password,
            },
            skipAuth : true,
        }
        API.post(API_PATH.URL_LOGIN_DETAILS, payload).then((res) => {
            if(res.status === 200) {
                if(res.data.auth_token!=''){
                    // localStorage.setItem('accessToken', res.data.auth_token);
                    getProfileData(res);
                }else{
                    message.error('Something went wrong, please try again!');
                }
            }else {
                message.error('Username or password wrong');
            }
        });
    }
    
    function getProfileData(data) {
        let userUrl = API_PATH.URL_USER_DETAILS;
        let headerData = {
            headers: {
              Authorization:
                "Token "+data.data.auth_token,
            } 
        }
        axios.get(userUrl, headerData).then( (res) => {
            localStorage.setItem('userData', JSON.stringify(res.data));
            props.loginUser(data);
            props.history.push(URL_ONA)
        }).catch((error) => {
            console.log(error);
        });
    }

    return (
        <LoginWrapper className="flex f-v-center f-h-center">
            <LoginBox className="box">
                <LogoWrapper>
                    <img className="img-logo-login" src={LogoImage} alt="Logo" />
                </LogoWrapper>
                <Form 
                    className   = "form-container"
                    onFinish    = {loginUser}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input size="large" placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please enter your password!' }]}
                    >
                        <Input.Password
                            placeholder="Password"
                            size="large"
                            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        />
                    </Form.Item>
                    {/* <Form.Item name="company" rules={[{ required: true, message: 'Please select company!' }]}>
                        <Select
                        placeholder="Select company"
                        allowClear
                        >
                        <Option value="1">male</Option>
                        <Option value="2">female</Option>
                        <Option value="3">other</Option>
                        </Select>
                    </Form.Item> */}
                    <div className="button-container">
                        <Button 
                            className   = "btn"
                            type        = "primary" 
                            shape       = "round" 
                            size        = "large"
                            htmlType    = "submit"
                        >
                            Login
                        </Button>
                    </div>
                </Form>
            </LoginBox>
        </LoginWrapper>
    )
}

const mapReduxStateToProps = (state) => ({
	isLoggedIn: state.Auth.isLoggedIn,
})

export default connect(mapReduxStateToProps, {
    loginUser
})(withRouter(Login));