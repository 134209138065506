import styled from "styled-components";

import { DIMENTIONS, FONTS } from "Styles/Constants";

export const RightContentWrapper = styled.div`
    margin-left: 0px;
    width: 100%;
    background: #FAFBFC;
    font-family : ${FONTS.PRIMARY};
    
    .ant-layout-footer {
        float: left;
        width: 100%;
        background: #FAFBFC;
        border-top: 1.5px solid #EAEAEA;
    }

    .antd-content {
        padding: 70px 0px 0px;
        flex-shrink: 0;
        background: #FBFBFC none repeat scroll 0% 0%;
        position: relative;
        overflow : auto;
    }

    .ant-layout {
    background: #FAFBFC;

    &.contentMainLayout {
        overflow: auto;
        overflow-x: hidden;
        @media only screen and (min-width: 768px) and (max-width: 1220px) {
            width: calc(100% - 80px);
            flex-shrink: 0;
        }

        @media only screen and (max-width: 767px) {
            width: 100%;
            flex-shrink: 0;
        }
        }
    }
`

export const ContentWrapper = styled.div`
    margin  : ${DIMENTIONS.HEADER}px 0px 0;
    overflow: initial;
    padding : 15px;

    
`