import React, { Component } from 'react';
import { Modal } from 'antd';
import { withRouter } from 'react-router-dom';
import { FooterWrapper } from './Footer.style';

import Terms from './Terms';

class Footer extends Component {
    state = { visible: false };
    showModal = (pageName, modelTitle) => {
        this.setState({
        visible: true,
        title: pageName,
        modelTitle: modelTitle
        });
    };
  
    handleOk = e => {
        console.log(e);
        this.setState({
        visible: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
        visible: false,
        });
    };

    render() {
        const title = this.state.title;
        const modelTitle = this.state.modelTitle;
        return (
            <FooterWrapper>
                <div className="footer-main">
                    <div className="footer-copy-right">
                        ©Whitebox HR 2020
                    </div>
                    <div className="footer-link">
                        <a target="blank" href={process.env.PUBLIC_URL+'/terms'}>Terms of Service</a>
                        <a target="blank" href={process.env.PUBLIC_URL+'/privacy'} >Privacy Policy</a>
                    </div>
                </div>
                <Modal
                    title={modelTitle}
                    visible={this.state.visible}
                    footer={null}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    style={{ top: 10 }}
                    width="520"
                >
                    <Terms page_title={title} /> 
                </Modal>
            </FooterWrapper>
        );
    }
}

export default (withRouter(Footer));