import React from "react";
import LazyLoader from "@loadable/component";
import {
  URL_DASHBOARD,
  URL_CANDIDATES,
  URL_EMPLOYEES,
  URL_SETTINGS,
  URL_REPORTS,
  URL_ONA,
  URL_THEMEOPTIONS,
  URL_ASSESSMENTS,
  URL_JOBS,
  URL_ONGOING_ASSESSMENTS_LIST,
  URL_COMPLETED_ASSESSMENTS_LIST,
  URL_ARCHIVED_ASSESSMENTS_LIST,
  URL_REPORTS_DETAILS
} from "Helpers/Paths";

import { AppstoreOutlined, SettingFilled, FormatPainterFilled } from "@ant-design/icons";

import CandidateActiveIcon from 'Assets/sidebar-icons/candidate-active.svg';
import CandidateInActiveIcon from 'Assets/sidebar-icons/candidate-inactive.svg';
import AssessmentsActiveIcon from 'Assets/sidebar-icons/assessments-active.svg';
import AssessmentsInActiveIcon from "Assets/sidebar-icons/assessments-inactive.svg";
import JobActiveIcon from 'Assets/sidebar-icons/job-active.svg';
import JobInActiveIcon from "Assets/sidebar-icons/job-inactive.svg";
import EmpActiveIcon from 'Assets/sidebar-icons/emp-active.svg';
import EmpInActiveIcon from "Assets/sidebar-icons/emp-inactive.svg";
import ReportActiveIcon from 'Assets/sidebar-icons/report-active.svg';
import ReportInActiveIcon from "Assets/sidebar-icons/report-inactive.svg";
import ONAActiveIcon from 'Assets/sidebar-icons/ona-active.svg';
import ONAInActiveIcon from "Assets/sidebar-icons/ona-inactive.svg";
import SettingIcon from "Assets/sidebar-icons/settings.svg";

export default [
  {
    path: "/",
    exact: true,
    component: LazyLoader(() => import("Components/ONA/ONA")),
  },
  {
    path: URL_DASHBOARD,
    exact: true,
    component: LazyLoader(() => import("Components/Dashboard/Dashboard")),
    sidebar: {
      name: "Dashboard",
      activeIcon: <AppstoreOutlined className="icon-color" />,
      icon: <AppstoreOutlined className="icon-color" />,
      antdIcon: true,
    },
  },
  {
    path: URL_CANDIDATES,
    exact: true,
    component: LazyLoader(() => import("Components/Candidates/Candidates")),
    sidebar: {
      name: "Candidates",
      activeIcon: (
        <div
          style={{
            backgroundImage: `url(${CandidateActiveIcon})`,
          }}
          className="sidebar-icon"
        />
      ),
      icon: (
        <div
          style={{
            backgroundImage: `url(${CandidateInActiveIcon})`,
          }}
          className="sidebar-icon"
        />
      ),
    },
  },
  {
    path: URL_EMPLOYEES,
    exact: true,
    component: LazyLoader(() => import("Components/Employees/Employees")),
    sidebar: {
      name: "Employees",
      activeIcon: (
        <div
          style={{
            backgroundImage: `url(${EmpActiveIcon})`,
          }}
          className="sidebar-icon"
        />
      ),
      icon: (
        <div
          style={{
            backgroundImage: `url(${EmpInActiveIcon})`,
          }}
          className="sidebar-icon"
        />
      ),
    },
  },
  {
    path: URL_JOBS,
    exact: true,
    component: LazyLoader(() => import("Components/Jobs/Jobs")),
    sidebar: {
      name: "Jobs",
      activeIcon: (
        <div
          style={{
            backgroundImage: `url(${JobActiveIcon})`,
          }}
          className="sidebar-icon"
        />
      ),
      icon: (
        <div
          style={{
            backgroundImage: `url(${JobInActiveIcon})`,
          }}
          className="sidebar-icon"
        />
      ),
    },
  },
  {
    path: URL_ASSESSMENTS,
    exact: true,
    component: LazyLoader(() => import("Components/Assessments/Assessments")),
    sidebar: {
      name: "Assessments",
      activeIcon: (
        <div
          style={{
            backgroundImage: `url(${AssessmentsActiveIcon})`,
          }}
          className="sidebar-icon"
        />
      ),
      icon: (
        <div
          style={{
            backgroundImage: `url(${AssessmentsInActiveIcon})`,
          }}
          className="sidebar-icon"
        />
      ),
    },
  },
  {
    path: URL_ONGOING_ASSESSMENTS_LIST,
    exact: true,
    component: LazyLoader(() => import("Components/Assessments/AssessmentsList")),
    // sidebar: {
    //   name: "Assessments",
    //   icon: <DropboxOutlined className="icon-color" />,
    // },
  },
  {
    path: URL_COMPLETED_ASSESSMENTS_LIST,
    exact: true,
    component: LazyLoader(() => import("Components/Assessments/AssessmentsList")),
    // sidebar: {
    //   name: "Assessments",
    //   icon: <DropboxOutlined className="icon-color" />,
    // },
  },
  {
    path: URL_ARCHIVED_ASSESSMENTS_LIST,
    exact: true,
    component: LazyLoader(() => import("Components/Assessments/AssessmentsList")),
    // sidebar: {
    //   name: "Assessments",
    //   icon: <DropboxOutlined className="icon-color" />,
    // },
  },
  {
    path: URL_REPORTS,
    exact: true,
    component: LazyLoader(() => import("Components/Report/Report")),
    sidebar: {
      name: "Reports",
      activeIcon: (
        <div
          style={{
            backgroundImage: `url(${ReportActiveIcon})`,
          }}
          className="sidebar-icon icon-color"
        />
      ),
      icon: (
        <div
          style={{
            backgroundImage: `url(${ReportInActiveIcon})`,
          }}
          className="sidebar-icon icon-color"
        />
      ),
      showDivider: true,
    },
  },
  {
    path: URL_ONA,
    exact: true,
    component: LazyLoader(() => import('Components/ONA/ONA')),
    sidebar: {
      name: 'ONA',
      activeIcon: (
        <div
          style={{
            backgroundImage: `url(${ONAActiveIcon})`,
          }}
          className="sidebar-icon icon-color"
        />
      ),
      icon: (
        <div
          style={{
            backgroundImage: `url(${ONAInActiveIcon})`,
          }}
          className="sidebar-icon icon-color"
        />
      ),
      showDivider: true,
    },
  },
  {
    path: URL_SETTINGS,
    exact: true,
    component: LazyLoader(() => import("Components/Settings/Settings")),
    sidebar: {
      name: "Settings",
      activeIcon: <SettingFilled className="icon-color" />,
      icon: <SettingFilled className="icon-color" />,
      antdIcon: true,
    },
  },
  {
    path: URL_THEMEOPTIONS,
    exact: true,
    component: LazyLoader(() => import("Components/ThemeOptions/ThemeOptions")),
    sidebar: {
      name: "Theme Options",
      activeIcon: <FormatPainterFilled className="icon-color" />,
      icon: <FormatPainterFilled className="icon-color" />,
      antdIcon: true,
    },
  },
  {
    path: URL_REPORTS_DETAILS,
    exact: true,
    component: LazyLoader(() => import("Components/Report/Report")),
  },
];
