// CORE
import React from 'react';
import { connect } from 'react-redux';

// THEME
import { Layout } from 'antd';

// USER DEFINED
import Routes from "Routes/Route";
import Sidebar from "./Commons/Sidebar/Sidebar";
import Header from "./Commons/Header/Header";
import FooterHTML from "./Commons/Footer/Footer";
import CommonPagesHeader from "./Commons/Header/CommonPagesHeader";
//STYLES
import { RightContentWrapper } from "./Website.style";

const { Content, Footer } = Layout;


function Website(props) {
    return (
        <React.Fragment>
            <Layout style={{ overflow: "hidden" }}>
                {
                    (window.location.pathname === '/terms' || window.location.pathname === '/privacy' || props.isLoggedIn ) 
                    ? <LoggedInComponents /> 
                    : <Routes />
                }
            </Layout>
        </React.Fragment>
    )
}

function LoggedInComponents(props) {
    return (
        <>
            <RightContentWrapper isLoggedIn={true}>
            { (window.location.pathname === '/terms' || window.location.pathname === '/privacy') ?  <CommonPagesHeader /> : <Header /> }
                <Layout style={{ flexDirection: 'row', overflowX: 'hidden' }}>
                    { (window.location.pathname === '/terms' || window.location.pathname === '/privacy') ? <CommonPagesHeader /> :  <Sidebar /> }
                    <Layout className="contentMainLayout">
                        <Content isLoggedIn={true} className="antd-content" >
                            <Routes />
                        </Content>

                        <Footer>
                            <FooterHTML />
                        </Footer>
                    </Layout>
                </Layout>
            </RightContentWrapper>
        </>
    )
}


const mapReduxStateToProps = (state) => ({
    isLoggedIn: state.Auth.isLoggedIn,
})

export default connect(mapReduxStateToProps)(Website);
