import { createGlobalStyle } from "styled-components";
import { FONTS, responsive } from "./Constants";

export const UtilityStyles = createGlobalStyle`
    * {
        box-sizing  : border-box;
        outline     : none;
    }
    html,
    body{
        margin: 0;
        padding: 0;
    }
    body{
        background-color    : #F4F7F8;
        font-family         : ${FONTS.PRIMARY};
        line-height         : 1.2;
        min-height          : 100vh;
        position            : relative;
    }
    #root {
        display: flex;
    }
    .full-height{
        height: 100%;
    }
    .full-width {
        width: 100%;
    }

    .flex {
        display: flex;
    }
    .f-v-center{
        align-items: center;
    }
    .f-h-center{
        justify-content: center;
    }

    .text-center{
        text-align: center;
    }
    .text-right {
        text-align: right;
    }
    .text-left {
        text-align: left !important;
    }

    .visible-md{
        display: none;
        ${responsive.TABLET`display:block`}
    }
    .hidden-md{
        ${responsive.TABLET`display:none`}
    }
    .plain-notification{
        background-color: #fff;
    }

    .elipsis {
        overflow        : hidden;
        text-overflow   : ellipsis;
        white-space     : nowrap;
    }
    
    .no-data-text {
        color       : #707070;
        font-size   : 15px;
        text-align  : center;
        width       : 100%;
    }
    .border-circle {
        border-radius: 25px;
    }
    .mg-tp-20 {
        margin-top: 20px;
    }
    .mg-tp-15 {
        margin-top: 15px;
    }
    .mg-bt-10 { 
        margin-bottom: 10px !important;
    }
    .mg-bt-20 {
        margin-bottom: 20px;
    }
    .mg-bt-25 {
        margin-bottom: 25px;
    }
    .mg-tp-bt-50 {
        margin-top: 50px;
        margin-bottom: 50px;
    }
    .mg-bt0 {
        margin-bottom: 0px !important;
    }
    .searchbar-card {
        border: none;
        border-radius: 10px;
        box-shadow: 6px 0px 10px #ccc;
    }
    .text-center { text-align: center; }
    .ant-radio-button-wrapper {
        height: 35px;
    }
    .ant-btn-primary {
        font-weight: 500;
        height: 35px;
        padding: 4px 25px;
    }
    .ant-form-item-label > label {
        color: #5C5C5C;
        font-weight: 500;
    }
    .card-border {
        border-radius: 10px;
    }
    .ant-card-bordered {
        border-radius: 10px;
        box-shadow: 0px 10px 30px #93939329;
        border: none;
    }
    .ant-menu-item {
        color: #333333;
        font-weight: 400;
        margin-bottom: 0px;
    }
    .ant-menu-item {
        font-family         : ${FONTS.PRIMARY};
    }
    .ant-menu:not(.ant-menu-horizontal) &.ant-menu-item-selected {
        background-color    : #FAFBFC;
        font-family         : ${FONTS.PRIMARY_SEMIBOLD};
        color               : #27A4A1;
    }
    .ant-menu-item-selected > .icon-color {
        color   : #27A4A1;
    }
    .ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child) {
        margin: 0px;
    }
    .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
        border: none;
    }
    .text-center {
        text-align: center;
    }
    .wd50 {
        width: 50% !important;
    }
    .ant-modal-body {
        float: left;
        width: 100%;
        background: #FFFF;
        padding: 0px;
    }
    .ant-select-dropdown {
        width: auto !important;
    }
    .ant-modal-title {
        font-size: 22px !important;
    }
    .bold {
        font-weight: bold;
    }
    .regular {
        font-weight: 400;
    }
    .semi-bold {
        font-weight: 500;
    }
    .mg-left10 {
        margin-left: 10px;
    }
    .mg-left0 {
        margin-left: 0px;
    }
    .common-dropdown .ant-select-selection-placeholder {
    color: #5c5c5c;
    font-size:14px;
    font-family  : ${FONTS.PRIMARY_SEMIBOLD};
    opacity: 1;
  }
  .common-dropdown.ant-select-single:not(.ant-select-customize-input) .ant-select-selector, .common-dropdown.ant-select-multiple .ant-select-selector {
    border-radius: 4px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E9E9F0;
  }
  .search-icon {
    top: 10px;
    position: absolute;
    z-index: 9;
    left: 10px;
    color: #bcbccb;
  }
  .search-input {
    padding-left: 30px;
    color: #5c5c5c;
    border-radius: 4px;
  }
  .search-input .ant-input::placeholder {
    color: #5c5c5c;
    opacity: 1;
    font-size:14px;
    font-family  : ${FONTS.PRIMARY_SEMIBOLD};
  }

  .icon-color {
      color : #ACACAC;
  }
  .traits-tag {
      background-color: #27A4A1;
      color: #fff;
      margin-top: 20px;
      padding: 5px 12px;
      border-radius: 5px;
  }
  .department-tag {
    background-color: rgba(147, 147, 147, 0.16);
    color: rgb(0, 0, 0);
      margin-top: 20px;
      padding: 5px 12px;
      border-radius: 5px;
  }
  .job-tag {
    background-color: #B1B1B1;
      color: #fff;
      margin-top: 20px;
      padding: 5px 12px;
      border-radius: 5px;
  }
  .location-tag {
    background-color: #27A4A14D;
      color: #000;
      margin-top: 20px;
      padding: 5px 12px;
      border-radius: 5px;
  }

  .location-tag, .job-tag , .department-tag, .traits-tag {
  .anticon-close {
    color: #333333;
  }
  }
  .ant-tag > .anticon + span, .ant-tag > span + .anticon {
    margin-left: 15px;
}
   .ant-select-item-option-state {
      display: none;
  }
  .reset-btn {
    background-color: rgba(147,147,147,0.07);
    color: rgb(0, 0, 0);
      margin-top: 20px;
      margin-bottom: 10px;
      padding: 5px 15px;
      border-radius: 5px;
      border: 1px solid rgba(147,147,147,0.7);
      cursor:pointer;
  }
  .reset-icon {
      margin-right: 15px;
  }
  /* .multi-dropdown.ant-select-dropdown .ant-select-item-option-content {
      cursor: none;
  }  */

  .filter-two {
    float: left;
    width: 45px;
    height: 45px;
    background-color: rgba(211, 211, 211,0.5) !important;
    border-color: rgba(211, 211, 211,0.5) !important;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    padding: 0;
  }
  .filter-two .like-icon {
    color: rgba(211, 211, 211,1);
  }
  .filter-two .dislike-icon {
    color: rgba(211, 211, 211,1);
  }

  .filter-two-like {
    float: left;
    width: 45px;
    height: 45px;
    background-color: rgba(24, 123, 125,0.5) !important;
    border-color: rgba(24, 123, 125,0.5) !important;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    padding: 0;
  }
  .filter-two-like .like-icon {
    color: rgba(24, 123, 125,1);
  }

  .filter-two-dislike {
    float: left;
    width: 45px;
    height: 45px;
    background-color: rgba(128,0,0,0.5) !important;
    border-color: rgba(128,0,0,0.5) !important;
    border-radius: 50%;
    margin-right: 10px;
    margin-top: 10px;
    cursor: pointer;
    padding: 0;
  }
  .filter-two-dislike .dislike-icon {
    color: rgba(128,0,0,1);
  }


  .sidebar-icon {
    background-size  : 100%;
    height           : 20px;
    width            : 20px;
    margin           : 0 10px 0 0;
    background-repeat  : no-repeat;
  }

  .icon-color {
    color : #ACACAC;
    
}
.anticon.icon-color {
    font-size : 20px;
}
.ant-menu-inline-collapsed > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
    left: 0;
    padding: 0 30px;
    text-overflow: clip;
}
.ant-menu-inline-collapsed > .ant-menu-item .anticon, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon, .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title .anticon, .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
    font-size: 20px;
}
.mg-tp0 {
    margin-top: 0px !important;
}
    .common-dropdown .ant-select-selection-item {
        font-family: Segoeui Semi Bold;
        color: #5c5c5c;
    }
`;
