export const ACTION_TYPES = {
    LOGIN_USER      : "LOGIN_USER",
    LOGOUT_USER     : "LOGOUT_USER",
    COLLPSE_CHANGE  : 'COLLPSE_CHANGE'
}
export const loginUser = (data) => {
    // SET YOUR LOGIN INFO HERE
    localStorage.setItem('isLoggedIn', true);
    localStorage.setItem('accessToken', data.data.auth_token);
    return {
        type : ACTION_TYPES.LOGIN_USER,
        ...data
    }
}

export const logOutUser = () => {
    localStorage.clear();
    return {
        type : ACTION_TYPES.LOGOUT_USER,
    }
}

export const toggleCollapsed = () => {
    return {
        type: ACTION_TYPES.COLLPSE_CHANGE
    }
}