import React from "react";
import { withRouter } from 'react-router-dom';
import { Menu, Dropdown, Button } from "antd";
import {
  DownOutlined,
  UserOutlined,
  LogoutOutlined,
  MenuOutlined,
} from "@ant-design/icons";
// STYLES
import { HeaderWrapper, LogoWrapper } from "./Header.style";
import { toggleCollapsed, logOutUser } from "Redux/Auth/Actions";
import { connect } from "react-redux";
import LogoImage from "Assets/Images/logo.png";


function handleMenuClick(e) {
  // message.info("Click on menu item.");
  console.log("click", e);
}

function logout() {
  localStorage.clear();
  window.location.href = process.env.PUBLIC_URL+'/login';
}

function Topbar(props) {

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="2" icon={<UserOutlined />}
        onClick={() => {        
          props.history.push("/settings");
        }}
      >
        Edit Profile
      </Menu.Item>
      <Menu.Item key="3" 
        onClick={logout}
        icon={<LogoutOutlined />}>
        Logout
      </Menu.Item>
    </Menu>
  );

  var userData = JSON.parse(localStorage.getItem('userData'));
  return (
    <HeaderWrapper className="header" collapsed={props.collapsed}>
      <div className="header">
        <div
          className="isoLeft"
          style={{
            fontSize: "16px",
            color: "#040707",
            display: "flex",
            alignItems: "center",
            WebkitBoxAlign: "center",
          }}
        >
          <MenuOutlined onClick={props.toggleCollapsed} />
          <LogoWrapper
            className="flex f-v-center f-h-center"
            style={{ margin: "0 0 0 20px" }}
          >
            <img className="img-logo" src={LogoImage} alt="Logo" />
          </LogoWrapper>
        </div>
        <div
          className="header-main"
          style={{
            display: "flex",
            alignItems: "center",
            WebkitBoxAlign: "center",
          }}
        >
          <Dropdown overlay={menu}>
            <Button className="drpdown-profile">
              <UserOutlined />
                {userData && userData.first_name}
              <DownOutlined />
            </Button>
          </Dropdown>
        </div>
      </div>
    </HeaderWrapper>
  );
}

const mapReduxStateToProps = (state) => ({
  collapsed: state.Auth.collapsed,
  width: state.Auth.width,
});

export default connect(mapReduxStateToProps, {
  toggleCollapsed,
})(withRouter(Topbar));
