import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import { Menu } from 'antd';

// STYLES
import { SidebarWrapper, MenuItem } from "./Sidebar.style";

// CONST
import Routes from "Routes/RoutesList.jsx";

import { toggleCollapsed } from "Redux/Auth/Actions";
import { connect } from 'react-redux';

function Sidebar(props) {
    let [routes, setRoutes] = useState([]);

    useEffect(() => {
        let filtered = Routes.filter((route) => route.sidebar)
        setRoutes(filtered)
    }, [])

    function listItemClick(route) {
        props.history.push(route.path)
    }

    return (
      <SidebarWrapper collapsed={props.auth.collapsed}>
        <Menu
          mode="inline"
          defaultSelectedKeys={[props.history.location.pathname]}
          selectedKeys={[props.history.location.pathname]}
        >
          {routes.map((route) => (
            <MenuItem
              title={route.sidebar.name == 'Dashboard' ? false : route.sidebar.name}
              className={route.sidebar.antdIcon === true ? '' : "icon-image" }
              key={route.path}
              icon={
                props.location.pathname === route.path
                  ? route.sidebar.activeIcon
                  : route.sidebar.icon
              }
              onClick={() => {
                listItemClick(route);
              }}
            >
              {route.sidebar.name}
            </MenuItem>
          ))}
        </Menu>
      </SidebarWrapper>
    );
}
export default connect(
    state => ({
        auth: state.Auth,
    }),
    { toggleCollapsed }
)(withRouter(Sidebar));